import React, { useContext, useState, useEffect, useRef } from 'react';
import { FrontContext } from 'src/front/helpers/FrontContext';
import Link from 'src/front/components/Link';
import useLang from 'src/hooks/useLang';
import { API_URI, IS_SERVER, DIMENSION_UNITS } from 'src/constants';
import axios from 'axios';
import LoadingScreen from 'src/components/LoadingScreen';
import Helper from 'src/front/helpers/Helper';
import ComponentStyle from 'src/components/ComponentStyle';
import DimensionUnitsConvertor from 'src/utils/DimensionUnitsConvertor';

function Page404() {
  const [pageData, setPageData] = useState(
    //props.staticContext && props.staticContext.page
    //? props.staticContext.page :
    (typeof window == 'object' && window.__REACT_INIT_DATA__ && window.__REACT_INIT_DATA__.page ? window.__REACT_INIT_DATA__.page : null)
  );
  const [background, setBackground] = useState(
    //props.staticContext && props.staticContext.page
    //? props.staticContext.page :
    (typeof window == 'object' && window.__REACT_INIT_DATA__ && typeof window.__REACT_INIT_DATA__.background404 == 'string' ? window.__REACT_INIT_DATA__.background404 : null)
  );
  if (typeof window == 'object' && window.__REACT_INIT_DATA__ && window.__REACT_INIT_DATA__.page) delete window.__REACT_INIT_DATA__.page;
  if (typeof window == 'object' && window.__REACT_INIT_DATA__ && typeof window.__REACT_INIT_DATA__.background404 == 'string') delete window.__REACT_INIT_DATA__.background404;
  const frontContext = useContext(FrontContext);
  const lang = useLang('404');
  const isFirstRender = useRef(!IS_SERVER);

  if (isFirstRender.current) {
    document.body.className = 'page-not-found';
    isFirstRender.current = false;
  }

  useEffect(() => {
    const canonical = document.querySelector('[rel="canonical"]');
    if (canonical) canonical.remove();

    if (!pageData) {
      axios
        .get(`${API_URI}/front/page-404`, { withCredentials: true })
        .then(({ data }) => setPageData(data));
    }

    if (!background) {
      axios
        .get(`${API_URI}/front/get-background-by-city/${frontContext.myCities[0].id}`, { withCredentials: true })
        .then(({ data }) => setBackground(data));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (pageData && lang.loaded) {
      Helper.setSEO(pageData.page, lang, 'page');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageData, lang.loaded]);

  if (!IS_SERVER && (!pageData || background === null)) return <LoadingScreen/>;

  return (
    <ComponentStyle styles={Helper.transformStyles(lang.currentLanguage, [{ src: '/css/page-404.css' }, { src: '/css/page-404-media.css' }])} Preloader={<LoadingScreen/>}>
      <div id="page-404-wrapper" className="relative" style={{ backgroundImage: IS_SERVER ? "{print background404 ? 'url(' + Helper.getFileUrl('404-background', background404) + ')' : 'none'}" : (background ? `url(${Helper.getFileUrl('404-background', background)})` : 'none') }}>
        <div className="background-color">
          <header className="relative">
            <Link className="logo-wrapper inline-block relative" to={lang.l('/')}>
              <div></div>
              <img alt="Logo" className="relative" src={Helper.getFileUrl('logotype', 'logo_white.svg')}/>
            </Link>
          </header>
          <div className="content ta-center relative inline-block va-middle">
            <Link className="city-link" to={lang.l(IS_SERVER ? '{print myCities[0].link}' : frontContext.myCities[0].link)}>{IS_SERVER ? '{print myCities[0].name}' : frontContext.myCities[0].name}</Link>
            <div className="city-temp">{IS_SERVER ? "{print DimensionUnitsConvertor.temperature(myCities[0].weather.temp, dimensionUnits.temperature) + DIMENSION_UNITS.find('temperature', dimensionUnits.temperature).label.substring(1)}" : `${DimensionUnitsConvertor.temperature(frontContext.myCities[0].weather.temp, frontContext.dimensionUnits.temperature)}${DIMENSION_UNITS.find('temperature', frontContext.dimensionUnits.temperature).label.substring(1)}`}</div>
            <div className="image-404 relative inline-block">
              <img alt="Weather" className="city-weather-icon" src={IS_SERVER ? "{print Helper.getFileUrl('weather-icon', myCities[0].weather.icon) + '.svg'}" : `${Helper.getFileUrl('weather-icon', frontContext.myCities[0].weather.icon)}.svg`}/>
              <img alt="Page not found" src={Helper.getFileUrl('images', '404.svg')}/>
            </div>
            <h1 dangerouslySetInnerHTML={{ __html: lang.localizationText(IS_SERVER ? '{print page.page.title_h1}' : pageData.page.title_h1) }}/>
            <div className="page-text" dangerouslySetInnerHTML={{ __html: lang.localizationText(IS_SERVER ? '{print page.page.text}' : pageData.page.text) }}/>
            <div className="fs-0" id="links">
              <Link className="home" to={lang.l('/')}>
                <span className="inline-block va-middle"></span>
                <span className="inline-block va-middle">{lang.t('Home')}</span>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </ComponentStyle>
  );
}

export default Page404;